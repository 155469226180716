<template>
  <div class="serviceTopic">
    <div class="image"></div>
    <router-link :to="{name: linkTo}" class="veiwExample"> 觀看相關案例 </router-link>
    <div class="content">
      <div class="topic">{{topic}}</div>
      <hr/>
      <div class="description">{{description}}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      baseUrl: process.env.BASE_URL
    };
  },
  props: {
    linkTo: String,
    topic: String,
    description: String
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/style.scss";
.serviceTopic {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 31vw;
  min-width: 310px;
  height: 28vw;
  min-height: 285px;
  box-shadow: 2px 4px 20px 0px #777;
  transition: 0.2s;
  margin: 0 20px;
  margin-bottom: 50px;
  @media #{$tablet} {
    width: 80vw;
    height: 72.25vw;
  }
  .image {
    flex: 1.75;
    background-size: cover;
    width: 31vw;
    @media #{$tablet} {
      width: 80vw;
    }
    min-width: 310px;
    min-height: 175px;
    transition: 0.3s ease;
  }
  .veiwExample {
    display: none;
    top: 25%;
    left: 25%;
    position: absolute;
    justify-content: center;
    align-items: center;
    font-size: 27px;
    @media #{$tablet} {
      font-size: 20px;
    }
    width: 50%;
    height: 15%;
    text-decoration: none;
    background-color: #73cccf;
    color: #fff;
  }
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 100px;
    justify-content: center;
    align-items: center;
    padding: 0 10% 5% 10%;
    @media #{$phone} {
      padding: 0;
    }
    .topic {
      @include font(medium);
    }
    hr {
      background-color: #707070;
      width: 80%;
    }
    .description {
      @include font(smaller);
      @media #{$phone} {
        padding: 0 30px;
      }
    }
  }
  &:hover {
    transform: translate(-1px, -2px);
    box-shadow: 4px 8px 20px 2px #777;
    .image {
      filter: brightness(0.5);
    }
    .veiwExample {
      display: flex;
    }
  }
  @media #{$tablet} {
    &.hover {
      transform: translate(-1px, -2px);
      box-shadow: 4px 8px 20px 2px #777;
      .image {
        filter: brightness(0.5);
      }
      .veiwExample {
        display: flex;
      }
    }
  }
}
</style>
