<template>
  <div class="service">
    <header v-if="!inPage">
      <top-title v-if="!inPage"
      title="WHAT WE PROVIDE"
      subTitle="用心經營是我們的職責">
      </top-title>
    </header>
    <div class="container">
      <page-main-body v-if="!inPage"
        title="6大行銷服務"
        subTitle="Six Marketing Services"
        paragraph1="數位行銷千變萬化，隨著不同潮流翻新不同行銷手法，采億/采盟2013年至今在業界已奠定專業的數位行銷解決專家，不變的是我們價值核心，提供一系列的創意內容與行銷工具，建議一套適合您的解決方案。">
      </page-main-body>
      <back v-if="inPage" />
      <router-view class="routerView"/>
      <div class="topics" v-if="!inPage">
        <service-topic linkTo="website"
          topic="網站製作" class="website"
          description="一個結合社群機制與網站製作的開始">
        </service-topic>
        <service-topic linkTo="mediaAds"
          topic="媒體廣告投放" class="mediaAds"
          description="了解每次廣告目的提供專業的解決方案">
        </service-topic>
        <service-topic linkTo="visialDesign"
          topic="視覺設計" class="visialDesign"
          description="運用時下話題與視覺協助品牌經營自媒體">
        </service-topic>
        <service-topic linkTo="filming"
          topic="影片製作" class="filming"
          description="依照客戶需求與影片類型製作出符合您現行的需要">
        </service-topic>
        <service-topic linkTo="socialMediaManagement"
          topic="自媒體經營" class="socialMediaManagement"
          description="我們致力於發想客戶創意內容經營您的自媒體">
        </service-topic>
        <service-topic linkTo="promoting"
          topic="口碑宣傳" class="promoting"
          description="讓目標客戶加深了解企業的產品服務，最終達到企業宣傳的品牌目的">
        </service-topic>
      </div>
    </div>
    <footer-view v-if="!inPage"/>
  </div>
</template>

<script>
import topTitle from "@/components/topTitle.vue";
import pageMainBody from "@/components/pageMainBody.vue";
import serviceTopic from "@/components/serviceTopic.vue";
import footerView from "@/components/footerView.vue";
import back from "@/components/back.vue";

export default {
  name: "service",
  components: {
    topTitle,
    pageMainBody,
    serviceTopic,
    footerView,
    back
  },
  data() {
    return {
      inPage: false
    };
  },
  created() {
    this.inPage = false;
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.$parent.currentPage = "service";
    setTimeout(() => {
      this.$parent.isLoading = false;
    }, 1000);
  },
  methods: {
    handleScroll() {
      var st = window.pageYOffset || document.documentElement.scrollTop;
      var hook = window.innerHeight * 0.6;
      $(".serviceTopic").each(function() {
        if (
          st > $(this).offset().top - hook &&
          st < $(this).offset().top - window.innerHeight * 0.12
        ) {
          $(this).addClass("hover");
        } else {
          $(this).removeClass("hover");
        }
      });
    }
  },
  watch: {
    inPage() {
      $("html, body").animate({ scrollTop: 0 }, 0);
    }
  }
};
</script>

<style lang="scss" >
@import "@/assets/scss/style.scss";
header {
  background-image: url("../assets/image/service/topBanner.jpg");
}
.service {
  background-color: #fff;
  color: #707070;
  width: 100%;
  & .inPage {
    height: 0;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .routerView {
      position: absolute;
      top: 0;
      z-index: 9;
      background-color: #fff;
      width: 100%;
    }
    .topics {
      margin-top: 50px;
      max-width: 1300px;
      width: 90%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      .serviceTopic {
        &.website .image {
          background-image: url("../assets/image/service/website.jpg");
        }
        &.mediaAds .image {
          background-image: url("../assets/image/service/mediaAds.jpg");
        }
        &.visialDesign .image {
          background-image: url("../assets/image/service/visialDesign.jpg");
        }
        &.filming .image {
          background-image: url("../assets/image/service/filming.jpg");
        }
        &.socialMediaManagement .image {
          background-image: url("../assets/image/service/socialMediaManagement.jpg");
        }
        &.promoting .image {
          background-image: url("../assets/image/service/promoting.jpg");
        }
      }
    }
  }
}
</style>
